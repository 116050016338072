@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package mvlikhachev_site.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val IndieFlower_Regular: FontResource by 
      lazy { init_IndieFlower_Regular() }

  public val gerbera_black: FontResource by 
      lazy { init_gerbera_black() }

  public val gerbera_bold: FontResource by 
      lazy { init_gerbera_bold() }

  public val gerbera_light: FontResource by 
      lazy { init_gerbera_light() }

  public val gerbera_medium: FontResource by 
      lazy { init_gerbera_medium() }

  public val gerbera_regular: FontResource by 
      lazy { init_gerbera_regular() }
}

internal val Res.font.IndieFlower_Regular: FontResource
  get() = CommonMainFont0.IndieFlower_Regular

private fun init_IndieFlower_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:IndieFlower_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/mvlikhachev_site.composeapp.generated.resources/font/IndieFlower-Regular.ttf", -1, -1),
    )
)

internal val Res.font.gerbera_black: FontResource
  get() = CommonMainFont0.gerbera_black

private fun init_gerbera_black(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:gerbera_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/mvlikhachev_site.composeapp.generated.resources/font/gerbera_black.otf", -1, -1),
    )
)

internal val Res.font.gerbera_bold: FontResource
  get() = CommonMainFont0.gerbera_bold

private fun init_gerbera_bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:gerbera_bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/mvlikhachev_site.composeapp.generated.resources/font/gerbera_bold.otf", -1, -1),
    )
)

internal val Res.font.gerbera_light: FontResource
  get() = CommonMainFont0.gerbera_light

private fun init_gerbera_light(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:gerbera_light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/mvlikhachev_site.composeapp.generated.resources/font/gerbera_light.otf", -1, -1),
    )
)

internal val Res.font.gerbera_medium: FontResource
  get() = CommonMainFont0.gerbera_medium

private fun init_gerbera_medium(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:gerbera_medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/mvlikhachev_site.composeapp.generated.resources/font/gerbera_medium.otf", -1, -1),
    )
)

internal val Res.font.gerbera_regular: FontResource
  get() = CommonMainFont0.gerbera_regular

private fun init_gerbera_regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:gerbera_regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/mvlikhachev_site.composeapp.generated.resources/font/gerbera_regular.otf", -1, -1),
    )
)
