package com.mvlikhachev.app.presentation

import androidx.compose.runtime.Stable
import com.mvlikhachev.app.domain.model.WorkExperienceModel

@Stable
data class AppScreenState(
    val title: String,
    val subtitle: String,
    val introductionLabel: String,
    val introductionValue: String,
    val educationLabel: String,
    val educationValue: String,
    val skillsLabel: String,
    val skillsValue: List<Pair<String, List<String>>>,
    val experienceLabel: String,
    val experienceValue: List<WorkExperienceModel>,
    val interestsLabel: String,
    val interestsValue: List<String>,
    val languagesLabel: String,
    val languagesValue: List<Pair<String, Int>>,
    val contactsLabel: String,
    val contactsValue: List<Triple<String, String, String>>,
)