package com.mvlikhachev.app.presentation.widget.separator

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Divider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mvlikhachev.app.presentation.item.BottomShadow
import com.mvlikhachev.app.presentation.item.TopShadow
import com.mvlikhachev.app.theme.AppTheme
import com.mvlikhachev.app.theme.WhiteLight
import com.mvlikhachev.app.utils.Constants
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
fun SeparatorWidget(
    modifier: Modifier = Modifier,
    type: String? = Constants.Type.Line,
    surface: String? = Constants.Hierarchy.Primary,
    text: String? = null,
    metaText: String? = null,
    metaIcon: (@Composable () -> Unit)? = null
) {
    Column(
        modifier = modifier
            .fillMaxWidth()
    ) {
        when (type) {
            Constants.Type.Line,
            Constants.Type.ShadowUp,
            Constants.Type.ShadowDown -> {
                val color = when (type) {
                    Constants.Type.Line -> {
                        AppTheme.colors.customColors.purple100LC
                    }

                    else -> Color.Transparent
                }

                if (type == Constants.Type.ShadowUp) {
                    TopShadow(
                        height = 16.dp,
                        color = AppTheme.colors.customColors.black
                    )
                }

                Divider(
                    thickness = 1.dp,
                    color = color,
                    modifier = Modifier
                        .fillMaxWidth()
                )
                if (type == Constants.Type.ShadowDown) {
                    BottomShadow(
                        height = 16.dp,
                        color = AppTheme.colors.customColors.black
                    )
                }
            }

            Constants.Type.Subheader -> {
                val backgroundColor = when(surface) {
                    Constants.Hierarchy.Primary -> {
                        Color.Transparent
                    }
                    else -> AppTheme.colors.customColors.purple100LC
                }
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(backgroundColor)
                        .padding(horizontal = 24.dp, vertical = 16.dp)
                ) {
                    text?.let {
                        Text(
                            text = it.uppercase(),
                            style = AppTheme.typography.subheader,
                            color = WhiteLight,
                        )
                    }

                    Row {
                        metaText?.let {
                            Text(
                                text = it.uppercase(),
                                style = AppTheme.typography.subheader,
                                color = AppTheme.colors.customColors.grey72LC,
                                fontWeight = FontWeight.Bold,
                                modifier = Modifier
                            )
                        }

                        if (metaIcon != null) {
                            metaIcon()
                        }
                    }
                }
            }
        }
    }
}

@Composable
@Preview()
fun SeparatorWidgetPreview() {
    AppTheme {
        Column {
            Text(
                text = "Type: Line",
                modifier = Modifier.padding(horizontal = 24.dp, vertical = 8.dp)
            )

            SeparatorWidget(
                type = Constants.Type.Line,
                surface = Constants.Hierarchy.Primary,
                modifier = Modifier
                    .padding(vertical = 8.dp)
            )

            SeparatorWidget(
                type = Constants.Type.ShadowUp,
                surface = Constants.Hierarchy.Primary,
                modifier = Modifier
                    .padding(vertical = 8.dp)
            )

            SeparatorWidget(
                type = Constants.Type.ShadowDown,
                surface = Constants.Hierarchy.Primary,
                modifier = Modifier
                    .padding(vertical = 8.dp)
            )

            Text(
                text = "Type: Subheader",
                modifier = Modifier.padding(horizontal = 24.dp, vertical = 8.dp)
            )

            SeparatorWidget(
                type = Constants.Type.Subheader,
                surface = Constants.Hierarchy.Primary,
                text = "subheader",
                modifier = Modifier
                    .padding(vertical = 8.dp)
            )

            SeparatorWidget(
                type = Constants.Type.Subheader,
                surface = Constants.Hierarchy.Primary,
                text = "subheader",
                metaText = "add-on",
                modifier = Modifier
                    .padding(vertical = 8.dp)
            )

            SeparatorWidget(
                type = Constants.Type.Subheader,
                surface = Constants.Hierarchy.Secondary,
                text = "subheader",
                modifier = Modifier
                    .padding(vertical = 8.dp)
            )

            SeparatorWidget(
                type = Constants.Type.Subheader,
                surface = Constants.Hierarchy.Secondary,
                text = "subheader",
                metaText = "add-on",
                modifier = Modifier
                    .padding(vertical = 8.dp)
            )
            SeparatorWidget(
                type = Constants.Type.Subheader,
                surface = Constants.Hierarchy.Secondary,
                text = "subheader",
                metaText = "add-on",
                metaIcon = {
//                    Icon(
//                        painter = painterResource(id = R.drawable.ic_icon_info),
//                        contentDescription = "ic_icon_cross",
//                        tint = GreyLight72LC,
//                        modifier = Modifier
//                            .padding(start = 8.dp)
//                            .padding(vertical = 2.dp)
//                            .size(16.dp)
//                    )
                },
                modifier = Modifier
                    .padding(vertical = 8.dp)
            )
        }
    }
}