package com.mvlikhachev.app.theme

import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.material.ripple.LocalRippleTheme
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.darkColorScheme
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.*
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import com.mvlikhachev.app.utils.ComposeUtils
import mvlikhachev_site.composeapp.generated.resources.IndieFlower_Regular
import mvlikhachev_site.composeapp.generated.resources.Res
import mvlikhachev_site.composeapp.generated.resources.gerbera_black
import mvlikhachev_site.composeapp.generated.resources.gerbera_bold
import mvlikhachev_site.composeapp.generated.resources.gerbera_light
import mvlikhachev_site.composeapp.generated.resources.gerbera_medium
import mvlikhachev_site.composeapp.generated.resources.gerbera_regular
import org.jetbrains.compose.resources.Font

// Светлая тема
val LightColorScheme = lightColorScheme(
    background = White.first,
    onBackground = Black.first,
    primary = Purple100LC.first,
    secondary = Purple16LC.first
)

// Темная тема
val DarkColorScheme = darkColorScheme(
    background = Black.second,
    onBackground = White.second,
    primary = Purple100LC.second,
    secondary = Purple16LC.second
)

@Immutable
data class BaseWidgetColors(val customColors: AppColors)

@Immutable
data class AppTypography(
    val display1: TextStyle = TextStyle.Default,
    val display2: TextStyle = TextStyle.Default,
    val header: TextStyle = TextStyle.Default,
    val subheader: TextStyle = TextStyle.Default,
    val main: TextStyle = TextStyle.Default,
    val secondary: TextStyle = TextStyle.Default,
    val tag: TextStyle = TextStyle.Default,
)

val LocalPaysendWidgetColors = staticCompositionLocalOf {
    BaseWidgetColors(
        customColors = AppColors(),
    )
}

val LocalReplacementTypography = staticCompositionLocalOf {
    AppTypography()
}

internal val LocalThemeIsDark = compositionLocalOf { mutableStateOf(true) }

@Composable
internal fun AppTheme(
    content: @Composable() () -> Unit
) {
    val systemIsDark = isSystemInDarkTheme()
    val isDarkState = remember { mutableStateOf(systemIsDark) }
    val darkTheme by isDarkState
    val customColors = BaseWidgetColors(
        customColors = AppColors(
            white = if (!darkTheme) White.first else White.second,
            black = if (!darkTheme) Black.first else Black.second,
            grey8LC = if (!darkTheme) Grey8LC.first else Grey8LC.second,
            grey16LC = if (!darkTheme) Grey16LC.first else Grey16LC.second,
            grey40LC = if (!darkTheme) Grey40LC.first else Grey40LC.second,
            grey72LC = if (!darkTheme) Grey72LC.first else Grey72LC.second,
            grey16TR = if (!darkTheme) Grey16TR.first else Grey16TR.second,
            purple8LC = if (!darkTheme) Purple8LC.first else Purple8LC.second,
            purple16LC = if (!darkTheme) Purple16LC.first else Purple16LC.second,
            purple24LC = if (!darkTheme) Purple24LC.first else Purple24LC.second,
            purple40LC = if (!darkTheme) Purple40LC.first else Purple40LC.second,
            purple72LC = if (!darkTheme) Purple72LC.first else Purple72LC.second,
            purple100LC = if (!darkTheme) Purple100LC.first else Purple100LC.second,
            purple72HC = if (!darkTheme) Purple72HC.first else Purple72HC.second,
            purple8TR = if (!darkTheme) Purple8TR.first else Purple8TR.second,
            purple16TR = if (!darkTheme) Purple16TR.first else Purple16TR.second,
            purple24TR = if (!darkTheme) Purple24TR.first else Purple24TR.second,
            purple40TR = if (!darkTheme) Purple40TR.first else Purple40TR.second,
            purple72TR = if (!darkTheme) Purple72TR.first else Purple72TR.second,
            pink8LC = if (!darkTheme) Pink8LC.first else Pink8LC.second,
            pink16LC = if (!darkTheme) Pink16LC.first else Pink16LC.second,
            pink40LC = if (!darkTheme) Pink40LC.first else Pink40LC.second,
            pink72LC = if (!darkTheme) Pink72LC.first else Pink72LC.second,
            pink100 = if (!darkTheme) Pink100.first else Pink100.second,
            pink8TR = if (!darkTheme) Pink8TR.first else Pink8TR.second,
            pink16TR = if (!darkTheme) Pink16TR.first else Pink16TR.second,
            pink40TR = if (!darkTheme) Pink40TR.first else Pink40TR.second,
            pink72TR = if (!darkTheme) Pink72TR.first else Pink72TR.second,
            greyShadow = GreyShadow48,
            noDarkThemeWhite = White.first,
            promoSubtitle = PromoWhiteWithAlpha
        )
    )

    val gerberaFontFamily = FontFamily(
        Font(Res.font.gerbera_regular, weight = FontWeight.Normal),
        Font(Res.font.gerbera_bold, weight = FontWeight.Bold),
        Font(Res.font.gerbera_light, weight = FontWeight.Light),
        Font(Res.font.gerbera_black, weight = FontWeight.Black),
        Font(Res.font.gerbera_medium, weight = FontWeight.Medium),
    )

    val customTypography = AppTypography(
        display1 = TextStyle(
            fontSize = 40.sp,
            lineHeight = 48.sp,
            fontFamily = gerberaFontFamily,
            fontWeight = FontWeight(400),
        ),
        display2 = TextStyle(
            fontSize = 32.sp,
            lineHeight = 40.sp,
            fontFamily = gerberaFontFamily,
            fontWeight = FontWeight(400),
        ),
        header = TextStyle(
            fontSize = 24.sp,
            lineHeight = 36.sp,
            fontFamily = gerberaFontFamily,
            fontWeight = FontWeight(400),
        ),
        subheader = TextStyle(
            fontSize = 14.sp,
            lineHeight = 20.sp,
            fontFamily = gerberaFontFamily,
            fontWeight = FontWeight(500),
            letterSpacing = 1.12.sp,
        ),
        main = TextStyle(
            fontSize = 17.sp,
            lineHeight = 24.sp,
            fontFamily = gerberaFontFamily,
            fontWeight = FontWeight(400),
        ),
        secondary = TextStyle(
            fontSize = 14.sp,
            lineHeight = 20.sp,
            fontFamily = gerberaFontFamily,
            fontWeight = FontWeight(400),
        ),
        tag = TextStyle(
            fontSize = 8.sp,
            lineHeight = 10.sp,
            fontFamily = gerberaFontFamily,
            fontWeight = FontWeight(700),
            letterSpacing = 0.64.sp,
        )
    )



    CompositionLocalProvider(
        LocalThemeIsDark provides isDarkState,
        LocalPaysendWidgetColors provides customColors,
        LocalReplacementTypography provides customTypography,
        LocalRippleTheme provides ComposeUtils.NoRippleTheme,
    ) {

        SystemAppearance(!darkTheme)


        MaterialTheme(
            colorScheme = if (darkTheme) DarkColorScheme else LightColorScheme,
            content = { Surface(content = content) }
        )
    }
}

@Composable
internal expect fun SystemAppearance(isDark: Boolean)

object AppTheme {
    val colors: BaseWidgetColors
        @Composable get() = LocalPaysendWidgetColors.current
    val typography: AppTypography
        @Composable get() = LocalReplacementTypography.current
}
