package com.mvlikhachev.app.presentation.screen.loading

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.FastOutSlowInEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.tween
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableFloatStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.mvlikhachev.app.theme.AppTheme
import mvlikhachev_site.composeapp.generated.resources.Res
import mvlikhachev_site.composeapp.generated.resources.ic_cyclone
import org.jetbrains.compose.resources.vectorResource
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
fun LoadingScreen(
    modifier: Modifier = Modifier,
    opacity: Boolean = false
) {
    var currentRotation by remember {
        mutableFloatStateOf(0f)
    }

    val rotation = remember {
        Animatable(currentRotation)
    }

    val backgroundColor = if (opacity) Color.White
    else Color.White.copy(alpha = 0.96f)

    Column(
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start,
        modifier = modifier
            .fillMaxSize()
            .background(
                backgroundColor,
                RoundedCornerShape(12.dp)
            )
            .padding(32.dp)
    ) {
        LaunchedEffect(true) {
            rotation.animateTo(
                targetValue = 359f,
                animationSpec = infiniteRepeatable(
                    animation = tween(1500, easing = FastOutSlowInEasing),
                    repeatMode = RepeatMode.Restart
                )
            ) {
                currentRotation = value
            }
        }

        Icon(
            imageVector = vectorResource(Res.drawable.ic_cyclone),
//            painter = painterResource(id = R.drawable.ic_loading),
            contentDescription = "",
//            tint = PaysendTheme.colors.paysendColors.purple100LC,
            tint = MaterialTheme.colorScheme.onSurface,
            modifier = Modifier
                .rotate(rotation.value)
                .size(80.dp)
        )

        Text(
            text = "Hang on...",
//            style = PaysendTheme.typography.main,
//            color = PaysendTheme.colors.paysendColors.black,
            modifier = Modifier.padding(top = 24.dp)
        )
    }
}

@Preview()
@Composable
private fun LoadingScreenPreview() {
    AppTheme {
        LoadingScreen()
    }
}

@Preview()
@Composable
private fun LoadingScreenDarkPreview() {
    AppTheme {
        LoadingScreen()
    }
}