package com.mvlikhachev.app.presentation.screen.base

import androidx.lifecycle.viewModelScope
import com.mvlikhachev.app.presentation.screen.abstract.ComposeViewModel
import com.mvlikhachev.app.presentation.screen.abstract.UiEvent
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch

@Suppress("UNCHECKED_CAST")
abstract class BaseUiViewModel<Event : UiEvent, State : BaseUiState<*>, Effect : BaseUiEffect> :
    ComposeViewModel<Event, State, Effect>() {


    fun doNext(action: (() -> Unit)? = null) {
        setEffect {
            BaseUiEffect.Empty as Effect
        }
        action?.invoke()
    }

    fun defaultSuccessResultScreenValues() = ResultScreenValues(
        message = "Success",
        buttonText = null,
        opacity = false,
        duration = 1000L,
        doAfter = { doNext() }
    )

    fun defaultErrorResultScreenValues() = ResultScreenValues(
        message = "Error",
        buttonText = null,
        opacity = false,
        duration = 1000L,
        doAfter = { doNext() }
    )

    fun <D> runCoroutine(
        flow: Flow<D>,
        success: ((result: D) -> Unit)? = null,
        failure: ((th: Throwable) -> Unit)? = null,
        showLoading: Boolean = true
    ) {
        val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
            handleFailure(throwable, failure) {
                runCoroutine(flow, success, failure)
            }

        }

        viewModelScope.launch(exceptionHandler) {
            if (showLoading) setLoading(true)
            delay(1000)
            flow.collectLatest {
                if (showLoading) setLoading(false)
                success?.invoke(it)
            }
        }
    }

    fun handleFailure(
        th: Throwable,
        failure: ((th: Throwable) -> Unit)?,
//        navigator: Navigator,
        retry: () -> Unit
    ) {
        when (th) {
//            is CloudflareForbiddenException -> {
//                GtApplication.component.captchaManager()
//                    .handleCloudflareException(th, failure, retry)
//            }
//
//            is IllegalResponseCodeException -> {
//                if (failure != null) {
//                    failure.invoke(th)
//                } else {
//                    navigator.handleError(th)
//                }
//            }
//
//            is NoNetworkException -> {
//                if (failure != null) {
//                    failure.invoke(th)
//                } else {
//                    navigator.handleError(th)
//                }
//            }

            else -> {
                failure?.invoke(th)
            }
        }
    }

    fun getTranslated(messageKey: String, vararg params: String?) =
//        messageBundle.get(messageKey, *params)
        messageKey

    fun getTranslatedOrResString(key: String, resString: String): String =
        if (getTranslated(key) == key) {
            resString
        } else {
            getTranslated(key)
        }

    private fun setLoading(isLoading: Boolean) {
        setState {
            currentState.copy(isLoading = isLoading) as State
        }
    }

    fun String?.lokalise(): String? =
        this?.let {
            val key = it.trim().lowercase()
            takeIf { key.startsWith("lok.") }?.let {
                getTranslated(key)
            }
        } ?: this

}