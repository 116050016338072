package com.mvlikhachev.app.presentation.screen.error

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.mvlikhachev.app.presentation.screen.base.ResultScreenValues

@Composable
fun ErrorScreen(
    modifier: Modifier = Modifier,
    screenValues: ResultScreenValues = ResultScreenValues()
) {
    val backgroundColor = if (screenValues.opacity) Color.White
    else Color.White.copy(alpha = 0.96f)

    Column(
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start,
        modifier = modifier
            .fillMaxSize()
            .background(
                backgroundColor,
                RoundedCornerShape(12.dp)
            )
            .padding(32.dp)
    ) {
//        LottieWidget(
//            R.raw.error,
//            {
//                if (screenValues.buttonText == null)
//                    LaunchedEffect(Unit) {
//                        delay(screenValues.duration)
//                        screenValues.doAfter?.invoke()
//                    }
//            },
//            modifier
//        )
        Text(
            text = screenValues.message,
//            style = PaysendTheme.typography.main,
//            color = PaysendTheme.colors.paysendColors.black,
            modifier = Modifier.padding(top = 24.dp)
        )


    }

    screenValues.buttonText?.let {

//        Box(
//            modifier = modifier
//                .fillMaxSize()
//        ) {
//            ButtonPillWidget(
//                modifier = Modifier
//                    .padding(24.dp)
//                    .align(Alignment.BottomEnd),
//                buttonText = it,
//                shadow = true,
//                enabled = true
//            ) {
//                screenValues.doAfter?.invoke()
//            }
//        }
    }
}