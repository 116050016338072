package com.mvlikhachev.app

import com.mvlikhachev.app.domain.model.WorkExperienceModel
import com.mvlikhachev.app.presentation.AppScreenState
import com.mvlikhachev.app.presentation.screen.base.BaseUiEffect
import com.mvlikhachev.app.presentation.screen.base.BaseUiEvent
import com.mvlikhachev.app.presentation.screen.base.BaseUiState
import com.mvlikhachev.app.presentation.screen.base.BaseUiViewModel
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn

class AppViewModel : BaseUiViewModel<BaseUiEvent, BaseUiState<AppScreenState>, BaseUiEffect>() {
    override fun createInitialState(): BaseUiState<AppScreenState> {
        return BaseUiState(
            data = AppScreenState(
                title = "Maksim Likhachev",
                subtitle = "Android Developer",
                introductionLabel = "Introduction",
                introductionValue = "Hello there!\n\n" +
                        "   I'm a seasoned professional with several years of expertise in Android " +
                        "Development. Throughout this period, I've achieved significant milestones, including the " +
                        "development and support of products used by millions of users. My standout qualities " +
                        "include determination, teachability, hard work, and communicativeness, and my Android " +
                        "Development skills are pivotal to success in this field. If you're seeking someone with " +
                        "these qualities who can deliver results, feel free to contact me today!\n\n" +
                        "   In addition to my primary focus, I'm also delving into iOS development, using both the " +
                        "native Swift language and KMP (Kotlin Multiplatform Mobile), along with a bit of " +
                        "backend work (Kotlin + Ktor, Java + Spring).\n\n" +
                        "   I'm also the creator of a YouTube channel: Maxim Likhachev.",
                educationLabel = "Education",
                educationValue = "State and Municipal Management, RANEPA (Smolensk), 2013 - 2017",
                skillsLabel = "Technical Skills",
                skillsValue = listOf(
                    Pair("Languages", listOf("Kotlin", "Java", "JS")),
                    Pair("Frameworks and Libraries", listOf("Android SDK", "AAC", "Compose", "Retrofit", "RxJava", "Coroutines", "Dagger/Hilt", "Ktor", "Firebase", "Room", "etc")),
                    Pair("Architecture", listOf("Clean Architecture", "MVVM", "MVI", "SOLID", "DRY", "KISS")),
                    Pair("Cross-Platform", listOf("KMP")),
                    Pair("Tooling", listOf("Jira", "Figma")),
                    Pair("Version Control", listOf("Git")),
                    Pair("Others", listOf("OOP", "Spring Boot", "Hibernate", "JPA", "Ktor", "PostgreSQL", "Mongo", "Express", "React", "NodeJS", "SQL", "HTML", "CSS")),
                ),
                experienceLabel = "Professional Experience",
                experienceValue = listOf(
                    WorkExperienceModel(
                        company = "Paysend",
                        position = "Android Developer",
                        startDate = LocalDate(2021, 5, 9),
                        endDate = Clock.System.todayIn(TimeZone.currentSystemDefault()),
                        description = " Improvement of the company's mobile application, including screen layouts and " +
                                "client-server interaction. Creation and maintenance of new products, Paysend " +
                                "Plus and Paysend Grow, which involve developing logic, navigation, and screen " +
                                "layouts, discussing product logic, and implementing new technologies in the " +
                                "projects.\n" +
                                "   1. Paysend Plus is a product aimed at receiving transfers without commission " +
                                "fees. It operates on a monthly subscription basis.\n" +
                                "   2. Paysend Grow is a product aimed at improving the credit rating for users in " +
                                "the UK. It operates on a monthly subscription basis.\n" +
                                "   3. Designing the architecture of android application, creating technical " +
                                "documentation, and drafting contracts for interaction with the server side.",
                        stillWork = true,
                        responsibilities = listOf(
                            "Build Android applications",
                            "Provide mobile application support",
                            "Contribute to application debugging and testing",
                            "Publish applications on the Google Play Store",
                            "Create user manuals for the finished product",
                            "Assist in the preparation of necessary documentation",
                            "Enhance application design and functionality",
                            "Implement new services and application technologies",
                            "Design the application architecture",
                            "Develop client-server communication",
                            "Participate in the entire Android application development process",
                            "Assist with testing",
                        ),
                        companyUrl = "https://paysend.com/"
                    ),
                    WorkExperienceModel(
                        company = "Letstell.ru",
                        position = "Junior Android Developer",
                        startDate = LocalDate(2021, 3, 1),
                        endDate =  LocalDate(2021, 6, 1),
                        description = "",
                        stillWork = false,
                        responsibilities = listOf(
                            "Engineered features and UI/UX elements, adhering to modern design principles.",
                            "Facilitated smooth client-server interaction through REST APIs.",
                            "Focused on code optimization and refactoring, ensuring application performance.",
                            "Assist with testing",
                        ),
                        companyUrl = ""
                    )
                ),
                interestsLabel = "Side Projects and Interests",
                interestsValue = listOf(
                    "Guitar player",
                    "Creator of a YouTube channel focused on IT topics.",
                    "Actively studying iOS development using both native Swift and KMM (Kotlin Multiplatform Mobile)."
                ),
                languagesLabel = "Languages",
                languagesValue = listOf(
                    Pair("Russian", 7),
                    Pair("English", 3),
                    Pair("Serbian", 1),
                    Pair("Spanish", 1),
                ),
                contactsLabel = "Contact Information",
                contactsValue = listOf(
                    Triple("Phone", "+381616295204",""),
                    Triple("Email", "wwwqwwwqwwwqwwwq@gmail.com", "mailto:wwwqwwwqwwwqwwwq@gmail.com"),
                    Triple("Telegram", "@mlikhachev", "https://t.me/mlikhachev"),
                )
            )
        )
    }

    override fun handleEvent(event: BaseUiEvent) {

    }
}