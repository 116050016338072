package com.mvlikhachev.app.theme

import androidx.compose.ui.graphics.Color

//generated by https://m3.material.io/theme-builder#/custom
//Color palette was taken here: https://colorhunt.co/palettes/popular

data class AppColors(
    val white: Color = Color.Unspecified,
    val black: Color = Color.Unspecified,
    val grey8LC: Color = Color.Unspecified,
    val grey16LC: Color = Color.Unspecified,
    val grey40LC: Color = Color.Unspecified,
    val grey72LC: Color = Color.Unspecified,
    val grey16TR: Color = Color.Unspecified,
    val purple8LC: Color = Color.Unspecified,
    val purple16LC: Color = Color.Unspecified,
    val purple24LC: Color = Color.Unspecified,
    val purple40LC: Color = Color.Unspecified,
    val purple72LC: Color = Color.Unspecified,
    val purple100LC: Color = Color.Unspecified,
    val purple72HC: Color = Color.Unspecified,
    val purple8TR: Color = Color.Unspecified,
    val purple16TR: Color = Color.Unspecified,
    val purple24TR: Color = Color.Unspecified,
    val purple40TR: Color = Color.Unspecified,
    val purple72TR: Color = Color.Unspecified,
    val pink8LC: Color = Color.Unspecified,
    val pink16LC: Color = Color.Unspecified,
    val pink40LC: Color = Color.Unspecified,
    val pink72LC: Color = Color.Unspecified,
    val pink100: Color = Color.Unspecified,
    val pink8TR: Color = Color.Unspecified,
    val pink16TR: Color = Color.Unspecified,
    val pink40TR: Color = Color.Unspecified,
    val pink72TR: Color = Color.Unspecified,
    val greyShadow: Color = Color.Unspecified,
    val noDarkThemeWhite: Color = Color.Unspecified,
    val promoSubtitle: Color = Color.Unspecified,
)


// Light theme
internal val WhiteLight = Color(0xFFFFFFFF)
internal val BlackLight = Color(0xFF141414)
internal val GreyLight8LC = Color(0xFFF3F2F5)
internal val GreyLight16LC = Color(0xFFE4E4E5)
internal val GreyLight40LC = Color(0xFF9F9DA3)
internal val GreyLight72LC = Color(0xFF77767A)
internal val GreyLight16TR = Color(0x29646268)
internal val GreyLight28TR = Color(0x479F9DA3)
internal val PurpleLight8LC = Color(0xFFF4EFFF)
internal val PurpleLight16LC = Color(0xFFE9DEFF)
internal val PurpleLight24LC = Color(0xFFDECEFF)
internal val PurpleLight40LC = Color(0xFFC8ADFF)
internal val PurpleLight72LC = Color(0xFF9C6CFF)
internal val PurpleLight100LC = Color(0xFF7633FF)
internal val PurpleLight72HC = Color(0xFF5B2ABD)
internal val PurpleLight8TR = Color(0x147633FF)
internal val PurpleLight16TR = Color(0x297633FF)
internal val PurpleLight24TR = Color(0x3D7633FF)
internal val PurpleLight40TR = Color(0x667633FF)
internal val PurpleLight72TR = Color(0xB87633FF)
internal val PinkLight8LC = Color(0xFFFFF0F4)
internal val PinkLight16LC = Color(0xFFFFE2E9)
internal val PinkLight40LC = Color(0xFFFFB5C9)
internal val PinkLight72LC = Color(0xFFFF7B9E)
internal val PinkLight100 = Color(0xFFFF4778)
internal val PinkLight8TR = Color(0x14FF4778)
internal val PinkLight16TR = Color(0x29FF4778)
internal val PinkLight40TR = Color(0x66FF4778)
internal val PinkLight72TR = Color(0xB8FF4778)

// Dark Theme
internal val WhiteDark = Color(0xFF141414)
internal val BlackDark = Color(0xFFFFFFFF)
internal val GreyDark8LC = Color(0xFF1E1F1F)
internal val GreyDark16LC = Color(0xFF2A2C2C)
internal val GreyDark40LC = Color(0xFF373939)
internal val GreyDark72LC = Color(0xFF888E90)
internal val GreyDark16TR = Color(0x2999A8A8)
internal val GreyDark28TR = Color(0xA3373939)
internal val PurpleDark8LC = Color(0xFF1C1826)
internal val PurpleDark16LC = Color(0xFF251B37)
internal val PurpleDark24LC = Color(0xFF2D1F49)
internal val PurpleDark40LC = Color(0xFF3E266C)
internal val PurpleDark72LC = Color(0xFF5F35B2)
internal val PurpleDark100LC = Color(0xFF7C42F0)
internal val PurpleDark72HC = Color(0xFFA177F4)
internal val PurpleDark8TR = Color(0x147C42F0)
internal val PurpleDark16TR = Color(0x297C42F0)
internal val PurpleDark24TR = Color(0x3D7C42F0)
internal val PurpleDark40TR = Color(0x667C42F0)
internal val PurpleDark72TR = Color(0xB87C42F0)
internal val PinkDark8LC = Color(0xFF26191D)
internal val PinkDark16LC = Color(0xFF371E25)
internal val PinkDark40LC = Color(0xFF6C2E3F)
internal val PinkDark72LC = Color(0xFFB34361)
internal val PinkDark100 = Color(0xFFF1557F)
internal val PinkDark8TR = Color(0x14F1557F)
internal val PinkDark16TR = Color(0x29F1557F)
internal val PinkDark40TR = Color(0x66F1557F)
internal val PinkDark72TR = Color(0xB8F1557F)
internal val GreyShadow48 = Color(0x7A000000)
internal val PromoWhiteWithAlpha = Color(0xA3FFFFFF)

val White: Pair<Color, Color> = WhiteLight to WhiteDark
val Black: Pair<Color, Color> = BlackLight to BlackDark
val Grey8LC: Pair<Color, Color> = GreyLight8LC to GreyDark8LC
val Grey16LC: Pair<Color, Color> = GreyLight16LC to GreyDark16LC
val Grey40LC: Pair<Color, Color> = GreyLight40LC to GreyDark40LC
val Grey72LC: Pair<Color, Color> = GreyLight72LC to GreyDark72LC
val Grey16TR: Pair<Color, Color> = GreyLight16TR to GreyDark16TR
val Purple8LC: Pair<Color, Color> = PurpleLight8LC to PurpleDark8LC
val Purple16LC: Pair<Color, Color> = PurpleLight16LC to PurpleDark16LC
val Purple24LC: Pair<Color, Color> = PurpleLight24LC to PurpleDark24LC
val Purple40LC: Pair<Color, Color> = PurpleLight40LC to PurpleDark40LC
val Purple72LC: Pair<Color, Color> = PurpleLight72LC to PurpleDark72LC
val Purple100LC: Pair<Color, Color> = PurpleLight100LC to PurpleDark100LC
val Purple72HC: Pair<Color, Color> = PurpleLight72HC to PurpleDark72HC
val Purple8TR: Pair<Color, Color> = PurpleLight8TR to PurpleDark8TR
val Purple16TR: Pair<Color, Color> = PurpleLight16TR to PurpleDark16TR
val Purple24TR: Pair<Color, Color> = PurpleLight24TR to PurpleDark24TR
val Purple40TR: Pair<Color, Color> = PurpleLight40TR to PurpleDark40TR
val Purple72TR: Pair<Color, Color> = PurpleLight72TR to PurpleDark72TR
val Pink8LC: Pair<Color, Color> = PinkLight8LC to PinkDark8LC
val Pink16LC: Pair<Color, Color> = PinkLight16LC to PinkDark16LC
val Pink40LC: Pair<Color, Color> = PinkLight40LC to PinkDark40LC
val Pink72LC: Pair<Color, Color> = PinkLight72LC to PinkDark72LC
val Pink100: Pair<Color, Color> = PinkLight100 to PinkDark100
val Pink8TR: Pair<Color, Color> = PinkLight8TR to PinkDark8TR
val Pink16TR: Pair<Color, Color> = PinkLight16TR to PinkDark16TR
val Pink40TR: Pair<Color, Color> = PinkLight40TR to PinkDark40TR
val Pink72TR: Pair<Color, Color> = PinkLight72TR to PinkDark72TR
