package com.mvlikhachev.app.presentation.item

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mvlikhachev.app.theme.AppTheme
import com.mvlikhachev.app.theme.GreyLight72LC
import com.mvlikhachev.app.theme.PurpleLight100LC
import com.mvlikhachev.app.theme.WhiteLight

@Composable
fun SkillItem(
    modifier: Modifier,
    value: String
) {
    Text(
        text = value,
        style = AppTheme.typography.main,
        color = WhiteLight,
        fontWeight = FontWeight.Bold,
        modifier = modifier
            .padding(2.dp)
            .background(
                shape = RoundedCornerShape(8.dp),
                color = PurpleLight100LC
            )
            .padding(horizontal = 8.dp, vertical = 4.dp)
    )
}