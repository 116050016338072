package com.mvlikhachev.app.utils

import kotlinx.datetime.DatePeriod
import kotlinx.datetime.LocalDate

object DateUtils {

    fun calculateYearsAndMonthsBetween(startDate: LocalDate, endDate: LocalDate): Pair<Int, Int> {
        val period = DatePeriod(
            years = endDate.year - startDate.year,
            months = endDate.monthNumber - startDate.monthNumber,
            days = endDate.dayOfMonth - startDate.dayOfMonth
        )

        var years = period.years
        var months = period.months

        // Adjust for negative months
        if (months < 0) {
            years -= 1
            months += 12
        }

        return Pair(years, months)
    }

}