package com.mvlikhachev.app.presentation.item

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mvlikhachev.app.theme.AppTheme
import com.mvlikhachev.app.theme.GreyLight16LC
import com.mvlikhachev.app.theme.GreyLight72LC
import com.mvlikhachev.app.theme.PurpleLight100LC
import com.mvlikhachev.app.theme.WhiteLight

@Composable
fun StepItem(
    modifier: Modifier = Modifier,
    isCompete: Boolean,
    isCurrent: Boolean,
    currentStep: Int
) {
    val color = if (isCompete || isCurrent) {
        AppTheme.colors.customColors.purple100LC
    } else {
        AppTheme.colors.customColors.grey16LC
    }

    Box(
        contentAlignment = Alignment.Center,
        modifier = modifier
    ) {

        //Line
        HorizontalDivider(
            modifier = Modifier.align(Alignment.CenterStart),
            color = color,
            thickness = 15.dp
        )

        //Circle
        if (isCurrent) {
            val textMeasurer = rememberTextMeasurer()

            val textToDraw = when(currentStep) {
                1 -> "A1"
                2 -> "A2"
                3 -> "B1"
                4 -> "B2"
                5 -> "C1"
                6 -> "C2"
                7 -> "Native"
                else -> ""
            }

            val style = TextStyle(
                fontSize = 8.sp,
                color = WhiteLight,
                background = Color.Transparent
            )

            val textLayoutResult = remember(textToDraw) {
                textMeasurer.measure(textToDraw, style)
            }
//            Canvas(modifier = Modifier
//                .size(15.dp)
//                .align(Alignment.CenterEnd)
//                .border(
//                    shape = CircleShape,
//                    width = 2.dp,
//                    color = color
//                ),
//                onDraw = {
////                    drawCircle(
//////                        center = Offset(
//////                            x = center.x,
//////                            y = center.y
//////                        ),
////                        radius = 25f,
////                        color = innerCircleColor,
////                    )
////                    drawText(
////                        textMeasurer = textMeasurer,
////                        text = textToDraw,
////                        style = style,
////                        topLeft = Offset(
////                            x = center.x - textLayoutResult.size.width / 2,
////                            y = center.y - textLayoutResult.size.height / 2,
////                        )
////                    )
//                }
//            )
            Text(
                text = textToDraw,
                style = MaterialTheme.typography.labelMedium,
                color = AppTheme.colors.customColors.white,
                fontWeight = FontWeight.Bold,
                modifier = Modifier
            )
        }
    }
}