package com.mvlikhachev.app.presentation.widget.skill

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mvlikhachev.app.presentation.item.SkillItem
import com.mvlikhachev.app.theme.AppTheme
import com.mvlikhachev.app.theme.GreyLight72LC

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun SkillWidget(
    modifier: Modifier,
    data: Pair<String, List<String>>
) {

    Column(
        modifier = modifier
    ) {
        Text(
            text = data.first + ":",
            style = AppTheme.typography.main,
            color = AppTheme.colors.customColors.black,
            fontWeight = FontWeight.Bold,
            modifier = Modifier
        )

        FlowRow(
            modifier = Modifier
                .padding(8.dp)
        ) {
            data.second.forEach {
                SkillItem(
                    modifier = Modifier,
                    value = it
                )
            }
        }
    }
}