package com.mvlikhachev.app.domain.model

import androidx.compose.runtime.Immutable
import kotlinx.datetime.LocalDate

@Immutable
data class WorkExperienceModel(
    val company: String,
    val position: String,
    val startDate: LocalDate,
    val endDate: LocalDate,
    val description: String,
    val responsibilities: List<String>,
    val stillWork: Boolean = false,
    val companyUrl: String,
)