package com.mvlikhachev.app.presentation.screen.base

import com.mvlikhachev.app.presentation.screen.abstract.UiEffect


sealed class BaseUiEffect : UiEffect {

    data object Empty : BaseUiEffect()

    data class ShowSuccess(
        val screenValues: ResultScreenValues
    ) : BaseUiEffect()

    data class ShowError(
        val screenValues: ResultScreenValues
    ) : BaseUiEffect()

    //TODO add others
}