package com.mvlikhachev.app.presentation.screen.abstract

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.mvlikhachev.app.presentation.screen.info_bottom_sheet.InfoBottomSheetCallback
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asSharedFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.receiveAsFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

abstract class ComposeViewModel<Event : UiEvent, State : UiState, Effect : UiEffect> :
    ViewModel() {

    private val initialState: State by lazy { createInitialState() }
    abstract fun createInitialState(): State

    // Get Current State
    val currentState: State
        get() = _uiState.value

    private val _uiState: MutableStateFlow<State> = MutableStateFlow(initialState)
    val uiState = _uiState.asStateFlow()

    private val _event: MutableSharedFlow<Event> = MutableSharedFlow()
    val event = _event.asSharedFlow()

    private val _effect: Channel<UiEffect> = Channel()
    val effect = _effect.receiveAsFlow()

    init {
        subscribeEvents()
//        Log.d("TAG123", "VM init ${this::class.java}")
    }

    /**
     * Set new Event
     */
    fun setEvent(event: Event) {
        viewModelScope.launch { _event.emit(event) }
    }


    /**
     * Set new Ui State
     */
    protected fun setState(reduce: State.() -> State) {
        _uiState.update {
            it.reduce()
        }
    }

    /**
     * Set new Effect
     */
    fun setEffect(builder: () -> Effect) {
        viewModelScope.launch { _effect.send(builder()) }
    }

    /**
     * Start listening to Event
     */
    private fun subscribeEvents() {
        viewModelScope.launch {
            event.collect {
                handleEvent(it)
            }
        }
    }

    /**
     * Handle each event
     */
    abstract fun handleEvent(event: Event)

    fun createNotificationCallback(
        action0: (() -> Unit)? = null,
        action1: (() -> Unit)? = null,
        action2: (() -> Unit)? = null,
        action3: (() -> Unit)? = null,
        action4: (() -> Unit)? = null,
        cancel: (() -> Unit)? = null
    ) = object : InfoBottomSheetCallback {

        override fun onInfoBottomSheetResult(result: Flow<InfoBottomSheetCallback.Status>) {
            viewModelScope.launch {
                result.collect {
                    when (it) {
                        InfoBottomSheetCallback.Status.ACTION0 -> action0?.invoke()
                        InfoBottomSheetCallback.Status.ACTION1 -> action1?.invoke()
                        InfoBottomSheetCallback.Status.ACTION2 -> action2?.invoke()
                        InfoBottomSheetCallback.Status.ACTION3 -> action3?.invoke()
                        InfoBottomSheetCallback.Status.ACTION4 -> action4?.invoke()
                        InfoBottomSheetCallback.Status.CANCEL -> cancel?.invoke()
                    }
                }
            }
        }
    }

}