package com.mvlikhachev.app.presentation.widget.experience

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mvlikhachev.app.domain.model.WorkExperienceModel
import com.mvlikhachev.app.openUrl
import com.mvlikhachev.app.theme.AppTheme
import com.mvlikhachev.app.utils.onClick

@Composable
fun ExperienceWidget(
    modifier: Modifier,
    data: WorkExperienceModel
) {
    Row(
        modifier
            .fillMaxWidth()
    ) {
        Column(
            modifier = Modifier
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .padding(horizontal = 12.dp)
                    .fillMaxWidth()
                    .onClick {
                        if (data.companyUrl.isNotEmpty()) {
                            openUrl(data.companyUrl)
                        }
                    }
            ) {
                Text(
                    text = data.company,
                    style = AppTheme.typography.header,
                    fontWeight = FontWeight.Bold,
                    color = AppTheme.colors.customColors.black,
                    modifier = Modifier
                )

                Text(
                    text = " | ",
                    style = AppTheme.typography.main,
                    fontWeight = FontWeight.Bold,
                    color = AppTheme.colors.customColors.grey72LC,
                    modifier = Modifier
                        .padding(horizontal = 8.dp)
                )

                Text(
                    text = data.position,
                    style = AppTheme.typography.main,
                    fontWeight = FontWeight.Bold,
                    color = AppTheme.colors.customColors.grey72LC,
                    modifier = Modifier
                )
            }

            Text(
                text = data.startDate.month.name.lowercase()
                    .replaceFirstChar { it.uppercase() } + " " + data.startDate.year + " - ${
                    if (data.stillWork) "Present" else data.endDate.month.name.lowercase()
                        .replaceFirstChar { it.uppercase() } + " " + data.endDate.year
                } ",
                style = AppTheme.typography.header,
                color = AppTheme.colors.customColors.grey72LC,
                modifier = Modifier
                    .padding(horizontal = 12.dp)
            )

            HorizontalDivider(
                modifier = Modifier
                    .padding(vertical = 8.dp),
                color = AppTheme.colors.customColors.grey72LC,
                thickness = 1.dp
            )

            if (data.description.isNotEmpty()) {
                Text(
                    text = data.description,
                    style = AppTheme.typography.main,
                    color = AppTheme.colors.customColors.grey72LC,
                    modifier = Modifier
                        .padding(vertical = 8.dp)
                        .padding(horizontal = 12.dp)
                )
            }

            Text(
                text = "Responsibilities:",
                style = AppTheme.typography.header,
                color = AppTheme.colors.customColors.black,
                modifier = Modifier
                    .padding(top = 12.dp, bottom = 6.dp)
                    .padding(horizontal = 12.dp)
            )

            data.responsibilities.forEach {
                Text(
                    text = "• $it",
                    style = AppTheme.typography.main,
                    color = AppTheme.colors.customColors.grey72LC,
                    modifier = Modifier
                        .padding(vertical = 2.dp)
                        .padding(horizontal = 12.dp)
                )
            }
        }
    }
}