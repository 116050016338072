package com.mvlikhachev.app.presentation.widget.steps

import androidx.compose.foundation.layout.Row
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.mvlikhachev.app.presentation.item.StepItem

@Composable
fun StepsProgressBar(
    modifier: Modifier = Modifier,
    numberOfSteps: Int,
    currentStep: Int
) {
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically
    ) {
        for (step in 1..numberOfSteps) {
            StepItem(
                modifier = Modifier.weight(1F),
                isCompete = step <= currentStep,
                isCurrent = step == currentStep,
                currentStep = currentStep
            )
        }
    }
}