package com.mvlikhachev.app

import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.rememberNavController
import com.mvlikhachev.app.presentation.AppNavigation
import com.mvlikhachev.app.presentation.screen.base.BaseUiEffect
import com.mvlikhachev.app.presentation.screen.base.BaseUiEvent
import com.mvlikhachev.app.presentation.screen.base.BaseUiState
import com.mvlikhachev.app.presentation.screen.base.BaseUiViewModel
import com.mvlikhachev.app.presentation.screen.cv.CvScreen
import com.mvlikhachev.app.presentation.screen.overlay.OverlayScreen
import com.mvlikhachev.app.theme.AppTheme
import com.mvlikhachev.app.theme.LocalThemeIsDark
import com.mvlikhachev.app.utils.NavigationUtils.navigateNextWithSlide
import mvlikhachev_site.composeapp.generated.resources.Res
import mvlikhachev_site.composeapp.generated.resources.ic_dark_mode
import mvlikhachev_site.composeapp.generated.resources.ic_light_mode
import mvlikhachev_site.composeapp.generated.resources.theme
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.resources.vectorResource

@Composable
internal fun App() = AppTheme {
    Scaffold(
        containerColor = AppTheme.colors.customColors.white,
        modifier = Modifier
            .fillMaxSize()
            .windowInsetsPadding(WindowInsets.safeDrawing)
//            .onClick { appFocusManager.clearFocus() }
//            .semantics { if (BuildConfig.DEBUG) testTagsAsResourceId = true }
    ) {
        val viewModel: AppViewModel = AppViewModel()
        val screenState by viewModel.uiState.collectAsState()
        OverlayScreen(
            viewModel = viewModel as BaseUiViewModel<BaseUiEvent, BaseUiState<*>, BaseUiEffect>
        ) {
            val navController = rememberNavController()
            NavHost(
                navController = navController,
                startDestination = AppNavigation.CvScreenNavigation.route,
                modifier = Modifier
                    .fillMaxSize()
                    .statusBarsPadding()
                    .systemBarsPadding()
                    .imePadding()
            ) {
                navigateNextWithSlide(
                    route = AppNavigation.CvScreenNavigation.route,
                ) {
                    CvScreen(
                        modifier = Modifier
                            .fillMaxSize(),
                        screenState = screenState,
                    )
                }
            }
        }
    }
//    Column(
//        modifier = Modifier
//            .fillMaxSize()
//            .windowInsetsPadding(WindowInsets.safeDrawing)
//            .padding(16.dp),
//        horizontalAlignment = Alignment.CenterHorizontally
//    ) {
//        Text(
//            text = stringResource(Res.string.cyclone),
//            fontFamily = FontFamily(Font(Res.font.IndieFlower_Regular)),
//            style = MaterialTheme.typography.displayLarge
//        )
//
//        var isAnimate by remember { mutableStateOf(false) }
//        val transition = rememberInfiniteTransition()
//        val rotate by transition.animateFloat(
//            initialValue = 0f,
//            targetValue = 360f,
//            animationSpec = infiniteRepeatable(
//                animation = tween(1000, easing = LinearEasing)
//            )
//        )
//
//        Image(
//            modifier = Modifier
//                .size(250.dp)
//                .padding(16.dp)
//                .run { if (isAnimate) rotate(rotate) else this },
//            imageVector = vectorResource(Res.drawable.ic_cyclone),
//            colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.onSurface),
//            contentDescription = null
//        )
//
//        ElevatedButton(
//            modifier = Modifier
//                .padding(horizontal = 8.dp, vertical = 4.dp)
//                .widthIn(min = 200.dp),
//            onClick = { isAnimate = !isAnimate },
//            content = {
//                Icon(vectorResource(Res.drawable.ic_rotate_right), contentDescription = null)
//                Spacer(Modifier.size(ButtonDefaults.IconSpacing))
//                Text(
//                    stringResource(if (isAnimate) Res.string.stop else Res.string.run)
//                )
//            }
//        )
//
//        var isDark by LocalThemeIsDark.current
//        val icon = remember(isDark) {
//            if (isDark) Res.drawable.ic_light_mode
//            else Res.drawable.ic_dark_mode
//        }
//
//        ElevatedButton(
//            modifier = Modifier.padding(horizontal = 8.dp, vertical = 4.dp).widthIn(min = 200.dp),
//            onClick = { isDark = !isDark },
//            content = {
//                Icon(vectorResource(icon), contentDescription = null)
//                Spacer(Modifier.size(ButtonDefaults.IconSpacing))
//                Text(stringResource(Res.string.theme))
//            }
//        )
//
//        TextButton(
//            modifier = Modifier.padding(horizontal = 8.dp, vertical = 4.dp).widthIn(min = 200.dp),
//            onClick = { openUrl("https://github.com/terrakok") },
//        ) {
//            Text(stringResource(Res.string.open_github))
//        }
//    }
}

internal expect fun openUrl(url: String?)