package com.mvlikhachev.app.presentation.screen.overlay

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import com.mvlikhachev.app.presentation.screen.loading.LoadingScreen
import com.mvlikhachev.app.presentation.screen.base.BaseUiEffect
import com.mvlikhachev.app.presentation.screen.base.BaseUiEvent
import com.mvlikhachev.app.presentation.screen.base.BaseUiState
import com.mvlikhachev.app.presentation.screen.base.BaseUiViewModel
import com.mvlikhachev.app.presentation.screen.error.ErrorScreen
import com.mvlikhachev.app.presentation.screen.success.SuccessScreen

@Composable
fun OverlayScreen(
    viewModel: BaseUiViewModel<BaseUiEvent, BaseUiState<*>, BaseUiEffect>,
    content: @Composable () -> Unit,
) {
    val state by viewModel.uiState.collectAsState()

    val effects by viewModel.effect.collectAsState(BaseUiEffect.Empty)

    val isOverlay by remember {
        derivedStateOf {
            effects is BaseUiEffect.ShowError || effects is BaseUiEffect.ShowSuccess || state.isLoading
        }
    }


    Box(Modifier.fillMaxSize()) {
        content()

        AnimatedVisibility(
            isOverlay,
            enter = fadeIn(tween(200)),
            exit = fadeOut(tween(200))
        ) {
            LocalSoftwareKeyboardController.current?.hide()

            Box(
                Modifier
                    .fillMaxSize()
                    .clickable { }
            )

            when {
                state.isLoading -> {
                    LoadingScreen(opacity = state.hasOverlayOpacity)
                }

                effects is BaseUiEffect.ShowError -> {
                    (effects as? BaseUiEffect.ShowError)?.screenValues?.let {
                        ErrorScreen(
                            screenValues = it
                        )
                    }
                }

                effects is BaseUiEffect.ShowSuccess -> {
                    (effects as? BaseUiEffect.ShowSuccess)?.screenValues?.let {
                        SuccessScreen(
                            screenValues = it
                        )
                    }
                }
            }
        }
    }
}