package com.mvlikhachev.app.presentation

import androidx.compose.runtime.Immutable
import com.mvlikhachev.app.presentation.screen.base.BaseScreen

@Immutable
sealed class AppNavigation(
    override val route: String,
): BaseScreen(route) {
    data object CvScreenNavigation : AppNavigation(route = "cv_screen")
}