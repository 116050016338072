package com.mvlikhachev.app.utils

object Constants {
    object Animations {
        const val FADE_ANIMATION_DELAY = 200
    }

    object Size {
        const val Large = "large"
        const val Medium = "medium"
        const val Small = "small"
    }

    object Hierarchy {
        const val Emphasised = "emphasised"
        const val Primary = "primary"
        const val Secondary = "secondary"
        const val SecondaryAlt = "secondaryAlt"
        const val Destructive = "destructive"
    }

    object Variant {
        const val Display1 = "display1"
        const val Display2 = "display2"
        const val Header = "header"
        const val Subheader = "subheader"
        const val Main = "main"
        const val Secondary = "secondary"
        const val Tag = "tag"
        const val Simple = "simple"
        const val Circle = "circle"
    }

    object Type {
        val Line = "line"
        val Subheader = "subheader"
        val ShadowUp = "ShadowUp"
        val ShadowDown = "ShadowDown"
    }

    object Values {
        const val EMPTY = ""
        const val SPACE = " "
        const val NO_BREAK_SPACE = "\u00A0"
        const val DOT = NO_BREAK_SPACE + "\u00B7" + SPACE
    }
}