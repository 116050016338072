package com.mvlikhachev.app.presentation.screen.cv

import androidx.compose.foundation.ScrollState
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CalendarLocale
import androidx.compose.material3.ElevatedButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.util.fastForEachReversed
import com.mvlikhachev.app.openUrl
import com.mvlikhachev.app.presentation.AppScreenState
import com.mvlikhachev.app.presentation.screen.base.BaseUiState
import com.mvlikhachev.app.presentation.widget.experience.ExperienceWidget
import com.mvlikhachev.app.presentation.widget.separator.SeparatorWidget
import com.mvlikhachev.app.presentation.widget.skill.SkillWidget
import com.mvlikhachev.app.presentation.widget.steps.StepsProgressBar
import com.mvlikhachev.app.theme.AppTheme
import com.mvlikhachev.app.theme.BlackLight
import com.mvlikhachev.app.theme.GreyLight72LC
import com.mvlikhachev.app.theme.LocalThemeIsDark
import com.mvlikhachev.app.theme.WhiteLight
import com.mvlikhachev.app.utils.Constants
import com.mvlikhachev.app.utils.DateUtils
import com.mvlikhachev.app.utils.onClick
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import mvlikhachev_site.composeapp.generated.resources.Res
import mvlikhachev_site.composeapp.generated.resources.ic_dark_mode
import mvlikhachev_site.composeapp.generated.resources.ic_light_mode
import mvlikhachev_site.composeapp.generated.resources.theme
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.resources.vectorResource

@Composable
fun CvScreen(
    modifier: Modifier,
    screenState: BaseUiState<AppScreenState>
) {
    val scrollState = rememberScrollState()

    CvScreenContent(
        modifier = modifier,
        screenState = screenState.data,
        scrollState = scrollState,
    )
}

@Composable
fun CvScreenContent(modifier: Modifier, screenState: AppScreenState, scrollState: ScrollState) {
    Column(
        modifier = modifier
            .verticalScroll(scrollState)
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
            modifier = Modifier
                .fillMaxWidth()
                .background(AppTheme.colors.customColors.black)
                .padding(vertical = 32.dp),
        ) {
            Row(
                horizontalArrangement = Arrangement.End,
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                var isDark by LocalThemeIsDark.current
                val icon = remember(isDark) {
                    if (isDark) Res.drawable.ic_light_mode
                    else Res.drawable.ic_dark_mode
                }
                Icon(
                    imageVector = vectorResource(icon),
                    contentDescription = null,
                    tint = AppTheme.colors.customColors.white,
                    modifier = Modifier
                        .padding(horizontal = 24.dp)
                        .onClick { isDark = !isDark },
                )
            }
            Text(
                text = screenState.title,
                style = AppTheme.typography.display1,
                color = AppTheme.colors.customColors.white
            )
            Text(
                text = screenState.subtitle,
                style = AppTheme.typography.display2,
                color = AppTheme.colors.customColors.white
            )
        }

        SeparatorWidget(
            text = screenState.introductionLabel,
            type = Constants.Type.Subheader,
            surface = Constants.Hierarchy.Secondary,
            modifier = Modifier
                .padding(bottom = 8.dp)
        )
        Text(
            text = screenState.introductionValue,
            style = AppTheme.typography.main,
            color = GreyLight72LC,
            modifier = Modifier
                .padding(12.dp)
                .padding(horizontal = 12.dp)
        )

        SeparatorWidget(
            text = screenState.educationLabel,
            type = Constants.Type.Subheader,
            surface = Constants.Hierarchy.Secondary,
            modifier = Modifier
                .padding(vertical = 8.dp)
        )

        Text(
            text = screenState.educationValue,
            style = AppTheme.typography.main,
            color = AppTheme.colors.customColors.grey72LC,
            modifier = Modifier
                .padding(12.dp)
                .padding(horizontal = 12.dp)
        )

        SeparatorWidget(
            text = screenState.skillsLabel,
            type = Constants.Type.Subheader,
            surface = Constants.Hierarchy.Secondary,
            modifier = Modifier
                .padding(vertical = 8.dp)
        )

        screenState.skillsValue.forEach {
            SkillWidget(
                modifier = Modifier
                    .padding(horizontal = 12.dp),
                data = it
            )
        }

        val fromYear = screenState.experienceValue.lastOrNull()?.startDate
            ?: Clock.System.todayIn(TimeZone.currentSystemDefault())
        val currentDate = Clock.System.todayIn(TimeZone.currentSystemDefault())

        val experience = DateUtils.calculateYearsAndMonthsBetween(
            startDate = fromYear,
            endDate = currentDate
        )

        SeparatorWidget(
            text = screenState.experienceLabel + ": ${experience.first} years, ${experience.second} months",
            type = Constants.Type.Subheader,
            surface = Constants.Hierarchy.Secondary,
            modifier = Modifier
                .padding(top = 12.dp)
                .padding(vertical = 8.dp)
        )

        screenState.experienceValue.forEach {
            ExperienceWidget(
                modifier = Modifier
                    .padding(vertical = 16.dp),
                data = it
            )
        }

        SeparatorWidget(
            text = screenState.interestsLabel,
            type = Constants.Type.Subheader,
            surface = Constants.Hierarchy.Secondary,
            modifier = Modifier
                .padding(vertical = 8.dp)
        )

        screenState.interestsValue.forEach {
            Text(
                text = "+ $it",
                style = AppTheme.typography.main,
                color = AppTheme.colors.customColors.grey72LC,
                modifier = Modifier
                    .padding(vertical = 2.dp)
                    .padding(horizontal = 24.dp)
            )
        }

        SeparatorWidget(
            text = screenState.languagesLabel,
            type = Constants.Type.Subheader,
            surface = Constants.Hierarchy.Secondary,
            modifier = Modifier
                .padding(top = 12.dp)
                .padding(vertical = 8.dp)
        )

        screenState.languagesValue.forEach {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .padding(top = 12.dp)
                    .padding(horizontal = 12.dp)
                    .fillMaxWidth()
            ) {
                Text(
                    text = it.first + ":",
                    style = AppTheme.typography.main,
                    color = AppTheme.colors.customColors.black,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier
                        .padding(horizontal = 4.dp)
                        .padding(end = 4.dp)
                )

                StepsProgressBar(
                    modifier = Modifier,
                    numberOfSteps = 7,
                    currentStep = it.second
                )
            }
        }

        SeparatorWidget(
            text = screenState.contactsLabel,
            type = Constants.Type.Subheader,
            surface = Constants.Hierarchy.Secondary,
            modifier = Modifier
                .padding(top = 12.dp)
                .padding(vertical = 8.dp)
        )

        screenState.contactsValue.forEach {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .padding(top = 12.dp)
                    .padding(horizontal = 12.dp)
                    .fillMaxWidth()
            ) {
                Text(
                    text = it.first + ": ",
                    style = AppTheme.typography.main,
                    color = AppTheme.colors.customColors.black,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier
                )

                Text(
                    text = it.second,
                    style = AppTheme.typography.main,
                    color = AppTheme.colors.customColors.grey72LC,
                    modifier = Modifier
                        .onClick {
                            if (it.third.isNotEmpty()) {
                                openUrl(it.third)
                            }
                        }
                )
            }
        }

        Spacer(modifier = Modifier.height(48.dp))


        Text(
            text = "Compose Multiplatform ❤\uFE0F\uFE0F",
            style = AppTheme.typography.main,
            color = WhiteLight,
            textAlign = TextAlign.Center,
            modifier = Modifier
                .fillMaxWidth()
                .background(
                    color = AppTheme.colors.customColors.purple72LC
                )
                .padding(vertical = 24.dp)
                .onClick {
                    openUrl("https://www.jetbrains.com/lp/compose-multiplatform/")
                }
        )
    }
}