package com.mvlikhachev.app.presentation.item

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.mvlikhachev.app.theme.GreyLight16LC

@Composable
fun BottomShadow(alpha: Float = 0.1f, height: Dp = 8.dp, color: Color = GreyLight16LC) {
    Box(modifier = Modifier
        .fillMaxWidth()
        .height(height)
        .background(
            brush = Brush.verticalGradient(
                colors = listOf(
                    color.copy(alpha = alpha),
                    Color.Transparent,
                )
            )
        )
    )
}

@Composable
fun TopShadow(alpha: Float = 0.1f, height: Dp = 8.dp, color: Color = GreyLight16LC) {
    Box(modifier = Modifier
        .fillMaxWidth()
        .height(height)
        .background(
            brush = Brush.verticalGradient(
                colors = listOf(
                    Color.Transparent,
                    color.copy(alpha = alpha),
                )
            )
        )
    )
}